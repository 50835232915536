// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-b-2-b-tsx": () => import("./../../../src/pages/b2b.tsx" /* webpackChunkName: "component---src-pages-b-2-b-tsx" */),
  "component---src-pages-coupon-tsx": () => import("./../../../src/pages/coupon.tsx" /* webpackChunkName: "component---src-pages-coupon-tsx" */),
  "component---src-pages-email-frame-tsx": () => import("./../../../src/pages/email/frame.tsx" /* webpackChunkName: "component---src-pages-email-frame-tsx" */),
  "component---src-pages-email-index-tsx": () => import("./../../../src/pages/email/index.tsx" /* webpackChunkName: "component---src-pages-email-index-tsx" */),
  "component---src-pages-email-success-verify-tsx": () => import("./../../../src/pages/email/success_verify.tsx" /* webpackChunkName: "component---src-pages-email-success-verify-tsx" */),
  "component---src-pages-frame-form-order-frame-error-reject-tsx": () => import("./../../../src/pages/frame/form-order-frame/error-reject.tsx" /* webpackChunkName: "component---src-pages-frame-form-order-frame-error-reject-tsx" */),
  "component---src-pages-frame-form-order-frame-error-repeat-tsx": () => import("./../../../src/pages/frame/form-order-frame/error-repeat.tsx" /* webpackChunkName: "component---src-pages-frame-form-order-frame-error-repeat-tsx" */),
  "component---src-pages-frame-form-order-frame-error-technical-tsx": () => import("./../../../src/pages/frame/form-order-frame/error-technical.tsx" /* webpackChunkName: "component---src-pages-frame-form-order-frame-error-technical-tsx" */),
  "component---src-pages-frame-form-order-frame-index-tsx": () => import("./../../../src/pages/frame/form-order-frame/index.tsx" /* webpackChunkName: "component---src-pages-frame-form-order-frame-index-tsx" */),
  "component---src-pages-frame-form-order-frame-success-tsx": () => import("./../../../src/pages/frame/form-order-frame/success.tsx" /* webpackChunkName: "component---src-pages-frame-form-order-frame-success-tsx" */),
  "component---src-pages-frame-form-order-frame-verify-tsx": () => import("./../../../src/pages/frame/form-order-frame/verify.tsx" /* webpackChunkName: "component---src-pages-frame-form-order-frame-verify-tsx" */),
  "component---src-pages-halvabonus-1000-tsx": () => import("./../../../src/pages/halvabonus-1000.tsx" /* webpackChunkName: "component---src-pages-halvabonus-1000-tsx" */),
  "component---src-pages-halvabonus-3000-1-tsx": () => import("./../../../src/pages/halvabonus-3000-1.tsx" /* webpackChunkName: "component---src-pages-halvabonus-3000-1-tsx" */),
  "component---src-pages-halvabonus-3000-tsx": () => import("./../../../src/pages/halvabonus-3000.tsx" /* webpackChunkName: "component---src-pages-halvabonus-3000-tsx" */),
  "component---src-pages-halvabonus-fond-habenskogo-alternative-tsx": () => import("./../../../src/pages/halvabonus/fond-Habenskogo/alternative.tsx" /* webpackChunkName: "component---src-pages-halvabonus-fond-habenskogo-alternative-tsx" */),
  "component---src-pages-halvabonus-fond-habenskogo-index-tsx": () => import("./../../../src/pages/halvabonus/fond-Habenskogo/index.tsx" /* webpackChunkName: "component---src-pages-halvabonus-fond-habenskogo-index-tsx" */),
  "component---src-pages-inmyroom-error-tsx": () => import("./../../../src/pages/inmyroom/error.tsx" /* webpackChunkName: "component---src-pages-inmyroom-error-tsx" */),
  "component---src-pages-inmyroom-index-tsx": () => import("./../../../src/pages/inmyroom/index.tsx" /* webpackChunkName: "component---src-pages-inmyroom-index-tsx" */),
  "component---src-pages-inmyroom-sms-tsx": () => import("./../../../src/pages/inmyroom/sms.tsx" /* webpackChunkName: "component---src-pages-inmyroom-sms-tsx" */),
  "component---src-pages-inmyroom-success-tsx": () => import("./../../../src/pages/inmyroom/success.tsx" /* webpackChunkName: "component---src-pages-inmyroom-success-tsx" */),
  "component---src-pages-invitefriend-check-index-tsx": () => import("./../../../src/pages/invitefriend-check/index.tsx" /* webpackChunkName: "component---src-pages-invitefriend-check-index-tsx" */),
  "component---src-pages-referall-error-tsx": () => import("./../../../src/pages/referall/error.tsx" /* webpackChunkName: "component---src-pages-referall-error-tsx" */),
  "component---src-pages-referall-success-tsx": () => import("./../../../src/pages/referall/success.tsx" /* webpackChunkName: "component---src-pages-referall-success-tsx" */),
  "component---src-pages-referralhub-tsx": () => import("./../../../src/pages/referralhub.tsx" /* webpackChunkName: "component---src-pages-referralhub-tsx" */),
  "component---src-pages-v-koffer-tsx": () => import("./../../../src/pages/VKoffer.tsx" /* webpackChunkName: "component---src-pages-v-koffer-tsx" */),
  "component---src-templates-page-status-tsx": () => import("./../../../src/templates/pageStatus.tsx" /* webpackChunkName: "component---src-templates-page-status-tsx" */)
}

